/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import "./layout.css"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"

import favicon from "../images/favicon.ico"

const LandscapeMobile = styled.div`
  @media only screen and (max-device-width: 600px) and (orientation: landscape) {
    opacity: 1 !important;
  }
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: -50px;
  left: -50px;
  right: -50px;
  bottom: -50px;
  pointer-events: none;
  background-color: white;
  opacity: 0;
  z-index: 999;
  #disclaimer {
    color: black;
    font-size: 3vh;
    text-align: center;
  }
`

const StyledLayout = styled.div`
  cursor: default;
  color: black;
  background-color: white;
  max-width: 100vw;
  overflow: hidden;

  font-size: 1.6vh;
  line-height: 1.5;

  a,
  button,
  .link {
    cursor: pointer;
  }
  button {
    background: none;
    text-decoration: inherit;
    border: none;
    color: inherit;
  }
`

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <link rel="icon" href={favicon} />
      <StyledLayout
        className="metadata"
        siteTitle={data.site.siteMetadata.title}
      >
        <Helmet
          title="Arnaud Bresson"
          meta={[
            { name: `Arnaud Bresson is a french director`, content: `Sample` },
            { name: `keywords`, content: `Video, Director` },
          ]}
        />
        <main>{children}</main>
        <LandscapeMobile>
          <p id="disclaimer">
            For a better experience, <br />
            please turn your device in portrait mode
          </p>
        </LandscapeMobile>
      </StyledLayout>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
